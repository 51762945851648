.skills{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 51vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 13rem;
}

.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome> :nth-child(1) { 
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(2) {
    color: var(--orange);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome> :nth-child(3) {
    color: var(--grey);
    font-size: 14px;
    font-weight: 1rem;
}

.s-button {
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}

.cards>* {
    position: absolute;
}

/* blur */
.s-blur2{
    left: 14rem;
    top: 8rem;
}

.s-blur1 {
    left: 13rem;
    top: -18rem;
}


@media screen and (max-width: 480px){
    .skills{
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }
    .cards>*{
        position: static;
    }
}